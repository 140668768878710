import React from 'react';
import Hero from 'components/organisms/Hero/Hero';
import LandingPageContent from 'components/organisms/LandingPageContent/LandingPageContent';
import HeroSignUpForm from 'components/organisms/Hero/HeroSignUpForm';
import AppStoreLinks from 'components/atoms/AppStoreLinks/AppStoreLinks';
import Txt from 'components/atoms/Txt/Txt';

export interface Props {}

const Homepage: React.FC<Props> = () => {
  return (
    <main className="fadedPageBg">
      <Hero
        leftColumnContent={
          <>
            <Txt component="h1">
              Earn <Txt component="strong">real cash</Txt> with online surveys
            </Txt>
            <Txt component="p">
              Forget swag or points - withdraw your <Txt component="strong">cash instantly</Txt>{' '}
              with Qmee.
            </Txt>
            <AppStoreLinks showOnMobileOnly={true} />
          </>
        }
        rightColumnContent={
          <>
            <HeroSignUpForm />
          </>
        }
      />
      <LandingPageContent />
    </main>
  );
};

export default Homepage;
